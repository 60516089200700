<template>
  <div>
    <b-overlay :show="show" no-wrap fixed z-index="9999">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark"></b-spinner>
          <b-spinner type="grow" variant="dark"></b-spinner>
          <b-spinner small type="grow" variant="dark"></b-spinner>
        </div>
      </template>
    </b-overlay>
    <b-button variant="success" @click="openModal">ثبت استان</b-button>
    <b-card title="استان‌ها" class="mt-3">
      <b-button v-b-toggle.filterBox variant="primary" class="mb-3 position-absolute" style="top:20px; left: 20px;"
        size="sm"><i class="fa fa-bars"></i></b-button>
      <b-collapse id="filterBox" class="my-2">
        <b-card>
          <form @submit.prevent="searchItems()" id="search-item">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="title">اسم</label>
                  <b-form-input id="title" v-model="searchParams.name"></b-form-input>
                </div>
              </div>
              <div class="col-md-6">
                <b-form-group label="وضعیت" label-for="status">
                  <b-form-select id="status" v-model="searchParams.status">
                    <b-form-select-option value="1">فعال</b-form-select-option>
                    <b-form-select-option value="0">غیرفعال</b-form-select-option>
                    <b-form-select-option value="">همه</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </div>
            </div>
            <button class="btn btn-primary btn-sm float-left" type="submit" :disabled="disabled">جست و
              جو</button>
          </form>
        </b-card>
      </b-collapse>
      <b-table responsive striped hover v-if="items.data" :fields="table_fields" :items="items.data.data">
        <template v-slot:cell(post_delivery_price)="data">
          <p v-if="data.item.post_delivery_price">{{ numberFormat(data.item.post_delivery_price) }}</p>
        </template>
        <template v-slot:cell(pishtaz_delivery_price)="data">
          <p v-if="data.item.pishtaz_delivery_price">{{ numberFormat(data.item.pishtaz_delivery_price) }}</p>
        </template>
        <template v-slot:cell(tipax_delivery_price)="data">
          <p v-if="data.item.tipax_delivery_price">{{ numberFormat(data.item.tipax_delivery_price) }}</p>
        </template>
        <template v-slot:cell(free_amount)="data">
          <p v-if="data.item.free_amount">{{ numberFormat(data.item.free_amount) }}</p>
        </template>
        <template v-slot:cell(created_at)="data">
          {{ data.item.created_at | persianDate }}
        </template>
        <template v-slot:cell(status)="data">
          <span class="badge badge-success" v-if="data.item.status == 1">فعال</span>
          <span class="badge badge-light" v-else>غیرفعال</span>
        </template>
        <template v-slot:cell(edit)="data">
          <button class="btn btn-primary" @click="editItem(data.index)"><i class="fa fa-edit"></i></button>
        </template>
        <template v-slot:cell(delete)="data">
          <button class="btn btn-danger" @click="deleteItem(data.item.id)"><i class="fa fa-close"></i></button>
        </template>
      </b-table>
      <div class="alert alert-danger text-right" v-if="items.data && items.data.data == ''">
        <span class="text-right">موردی یافت نشد ! </span>
      </div>
    </b-card>

    <pagination :limit="2" v-if="items.data && !search" :data="items.data" @pagination-change-page="loadItems">
    </pagination>
    <pagination :limit="2" v-else-if="items.data && search" :data="items.data" @pagination-change-page="searchItems">
    </pagination>

    <b-modal id="new-modal" title="ثبت استان" hide-footer>
      <div>
        <form @submit.prevent="newItem" id="new-item">
          <b-form-group>
            <label for="name"> اسم <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
            <b-form-input id="name" name="name" data-required :disabled="disabled"></b-form-input>
          </b-form-group>
          <b-form-group label="هزینه پست معمولی (تومان)" label-for="price">
            <b-form-input v-model="post_delivery_price" name="post_delivery_price" :disabled="disabled"></b-form-input>
          </b-form-group>
          <b-form-group label="هزینه پست پیشتاز (تومان)" label-for="price">
            <b-form-input v-model="pishtaz_delivery_price" name="pishtaz_delivery_price" :disabled="disabled"></b-form-input>
          </b-form-group>
          <b-form-group label="هزینه تیپاکس (تومان)" label-for="price">
            <b-form-input v-model="tipax_delivery_price" :disabled="disabled"></b-form-input>
          </b-form-group>
          <b-form-group label="حد هزینه پست رایگان (تومان)" label-for="price">
            <b-form-input v-model="free_amount" :disabled="disabled"></b-form-input>
          </b-form-group>
          <div class="form-group">
            <label class="custom-switch">
              <input type="checkbox" name="status" class="custom-switch-input" v-model="selected_status"
                :disabled="disabled">
              <span class="custom-switch-indicator"></span>
              <span class="custom-switch-description">وضعیت</span>
            </label>
          </div>
          <input type="hidden" name="country_id" value="102">
          <input type="hidden" name="post_delivery_price" :value="post_delivery_price">
          <input type="hidden" name="pishtaz_delivery_price" :value="pishtaz_delivery_price">
          <input type="hidden" name="tipax_delivery_price" :value="tipax_delivery_price">
          <input type="hidden" name="free_amount" :value="free_amount">
          <div class="clearfix">
            <button class="btn btn-success float-right" type="submit" :disabled="disabled">ثبت</button>
            <b-button class="float-left" variant="white" type="button"
              @click="$root.$emit('bv::hide::modal', 'new-modal')">بستن</b-button>
          </div>
        </form>
      </div>
    </b-modal>

    <b-modal id="edit-modal" title="ویرایش استان" hide-footer>
      <div v-if="edit.id">
        <form @submit.prevent="itemEdited" id="edit-item">
          <b-form-group>
            <label for="name"> اسم <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
            <b-form-input id="name" name="name" v-model="edit.name" data-required :disabled="disabled"></b-form-input>
          </b-form-group>

          <b-form-group label="هزینه پست معمولی (تومان)" label-for="price">
            <b-form-input :value="edit.post_delivery_price" name="post_delivery_price" :disabled="disabled">
            </b-form-input>
          </b-form-group>
          <b-form-group label="هزینه پست پیشتاز (تومان)" label-for="price">
            <b-form-input :value="edit.pishtaz_delivery_price" name="pishtaz_delivery_price" disabled="disabled">
            </b-form-input>
          </b-form-group>
          <b-form-group label="هزینه تیپاکس (تومان)" label-for="price">
            <b-form-input  :value="edit.tipax_delivery_price" name="tipax_delivery_price" :disabled="disabled">
            </b-form-input>
          </b-form-group>
          <b-form-group label="حد هزینه پست رایگان (تومان)" label-for="price">
            <b-form-input :value="edit.free_amount" name="free_amount" :disabled="disabled"></b-form-input>
          </b-form-group>

          <div class="form-group">
            <label class="custom-switch">
              <input type="checkbox" name="status" class="custom-switch-input" v-model="edit.status"
                :disabled="disabled">
              <span class="custom-switch-indicator"></span>
              <span class="custom-switch-description">وضعیت</span>
            </label>
          </div>
          <input type="hidden" name="country_id" value="102">
          <input type="hidden" name="post_delivery_price" :value="edit.post_delivery_price">
          <input type="hidden" name="pishtaz_delivery_price" :value="edit.pishtaz_delivery_price">
          <input type="hidden" name="tipax_delivery_price" :value="edit.tipax_delivery_price">
          <!-- <input type="hidden" name="free_amount" :value="edit.free_amount"> -->
          <div class="clearfix">
            <button class="btn btn-primary float-right" type="submit" :disabled="disabled">ویرایش</button>
            <b-button class="float-left" variant="white" type="button"
              @click="$root.$emit('bv::hide::modal', 'edit-modal')">بستن</b-button>
          </div>
          <input type="hidden" value="PUT" name="_method">
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import mixins from '../mixins/mixins'

  export default {
    mixins: [mixins],
    data() {
      return {
        url: '/api/admin/province',
        title: 'استان ها',
        countries: [],
        selected_country: [],
        table_fields: [{
            key: 'id',
            label: 'شناسه '
          },
          {
            key: 'name',
            label: 'اسم'
          },
          {
            key: 'status',
            label: 'وضعیت'
          },
          {
            key: 'post_delivery_price',
            label: 'هزینه پست معمولی'
          },
          {
            key: 'pishtaz_delivery_price',
            label: 'هزینه پست پیشتاز'
          },
          {
            key: 'tipax_delivery_price',
            label: 'هزینه تیپاکس'
          },
          {
            key: 'free_amount',
            label: 'حد هزینه پست رایگان'
          },
          {
            key: 'created_at',
            label: 'تاریخ ثبت'
          },
          // { key: 'longitude', label: 'عرض جغرافیایی' },
          // { key: 'latitude', label: 'طول جغرافیایی' },
          {
            key: 'edit',
            label: 'ویرایش '
          },
          {
            key: 'delete',
            label: 'حذف'
          }
        ],
        selected_status: '1',
        selected_filter_status: '',
        free_amount: 0,
        post_delivery_price: 0,
        pishtaz_delivery_price: 0,
        tipax_delivery_price: 0,
      }
    },
    methods: {
      loadCountries() {
        this.$axios.get(this.$root.baseUrl + '/api/admin/list/countries?all=true')
          .then(response => {
            this.countries = response.data.data
          })

      },
      editItem(index) {
        this.edit = {}
        let item = window.clone(this.items.data.data[index])
        item.index = index
        this.edit = item
        this.price = this.edit.delivery_price
        this.amount = this.edit.free_amount
        this.$root.$emit('bv::show::modal', 'edit-modal')
      },
      openModal() {
        this.price = 0
        this.amount = 0
        this.$root.$emit('bv::show::modal', 'new-modal')
      },
      searchItems(page = 1) {
        this.disabled = true
        this.search = true
        let org_price = this.search_price <= 999 ? this.search_price : this.search_price.replace(/\D/g, "");
        // let org_amount = this.amount <= 999 ? this.amount : this.amount.replace(/\D/g, "");
        this.searchParams.delivery_price = org_price != 0 ? org_price : ''
        this.$axios.get(this.$root.baseUrl + this.url + '?page=' + page, {
            params: this.searchParams
          })
          .then(response => {
            this.search = true
            this.items = response.data
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })
      },
    },
    created() {
      // this.loadCountries()
      this.loadItems()
      this.searchParams = {
        name: '',
        delivery_time: '',
        status: '',
        delivery_price: 0
      }
    },

  }
</script>
